import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule, routingComponents } from './app-routing.module';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentDataService } from './content-data.service';
import { HttpClientModule } from '@angular/common/http';

import { sanitizeHtmlPipe } from './sanitize-html.pipe';

import { FootermenuComponent } from './footermenu/footermenu.component';

@NgModule({
  declarations: [
    AppComponent,
    FootermenuComponent,
    routingComponents, 
    sanitizeHtmlPipe
  ],
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [ContentDataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
