import { environment } from '../../environments/environment';

class ConsoleExtension {

    public readonly isTestEnvironment: boolean = false;
    public readonly isDebugEnabled: boolean = true;

    constructor(enableDebugging: boolean) {
        if(this.testlocal()==true) {
            this.isTestEnvironment = true;
        }
        this.isDebugEnabled = enableDebugging;

        // Override if production
        if (environment.production) {
            this.isTestEnvironment = false;
            this.isDebugEnabled = false;
        }
    }

    private testlocal(): boolean {
        let isLocal: boolean = false;
        if(window.location.hostname == "localhost" && window.location.port == "4200") {
            isLocal = true;
        }
        return isLocal;
    }

    info(message: string, data?: any): void {
        console.info(message, data);
    }
    debuginfo(message: string, data?: any): void {
        if(this.isDebugEnabled == true) {
            console.info(message, data);
        }
    }

    log(message: string, data?: any): void {
        console.log(message, data);
    }
    debuglog(message: string, data?: any): void {
        if(this.isDebugEnabled == true) {
            console.log(message, data);
        }
    }

    error(message: string, data?: any): void {
        console.error(message, data);
    }
    debugerror(message: string, data?: any): void {
        if(this.isDebugEnabled == true) {
            console.error(message, data);
        }
    }
}

export namespace consoleextension {
    let ce: ConsoleExtension = new ConsoleExtension(true);

    export let isTestEnvironment: boolean = ce.isTestEnvironment;
    export let isDebugEnabled: boolean = ce.isDebugEnabled;

    export function info(message: string, data?: any): void {
        ce.info(message, data);
    }
    export function debuginfo(message: string, data?: any): void {
        ce.debuginfo(message, data);
    }

    export function log(message: string, data?: any): void {
        ce.log(message, data);
    }
    export function debuglog(message: string, data?: any): void {
        ce.debuglog(message, data);
    }

    export function error(message: string, data?: any): void {
        ce.error(message, data);
    }
    export function debugerror(message: string, data?: any): void {
        ce.debugerror(message, data);
    }
}