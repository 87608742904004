import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IFootermenuitem } from './footermenu/footermenuitem';
import { IArtikelitem } from './artikel/artikelitem';
import { IArtikelitemImage } from './artikel/artikelitemimage';
import { Observable, throwError, Timestamp } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { consoleextension } from './utilities/ConsoleExtension';

@Injectable({
  providedIn: 'root'
})
export class ContentDataService {
  
  private serverUrl: string;

  constructor(
    private http: HttpClient
  ) {
    this.serverUrl = "https://ghvapp.markus-schratt.de";
    //this.serverUrl = "https://ghvapp.markus-schratt.de";
  }

  /****************************************************************************
   * Basic Auth
   ***************************************************************************/
  private formatBasicAuth(userName, password): string {
    var basicAuthCredential = userName + ":" + password;
    var bace64 =  btoa(basicAuthCredential);
    return 'Basic ' + bace64;
  }

  /****************************************************************************
   * CsrfToken
   ***************************************************************************/
  private getCsrfToken(): Observable<HttpResponse<string>> {
    let d: Date = new Date();
    let url: string = this.serverUrl + `/drupal/rest/session/token?uid=${d.getTime()}`;
    if(consoleextension.isTestEnvironment == true) {
      // for test reasons only
      url = "/assets/testdata/csrfToken";
      consoleextension.info("URL:", url);
    }

    return this.http.get(url, { observe: 'response', responseType: 'text' }).pipe(
      map(this.extractCsrfTokenData),
      catchError(this.handleErrorObservableCsrfToken)
    );
  }
  private handleErrorObservableCsrfToken(err, caught: Observable<HttpResponse<string>>) {
    var userout = `Es ist ein Fehler beim Laden des Tokens aufgetreten!`;
    return this.handleError(err, userout);
  }
  private extractCsrfTokenData(data) {
    consoleextension.debuginfo("CSRF TOKEN:", data);
    return data;
  }

  /****************************************************************************
   * ArtikelItems
   ***************************************************************************/
  createNewArtikelItem(
    username: string,
    password: string,
    title: string,
    body: string,
    reihenfolge: number
  ): number {
    let returnId: number = -1;
    let basic: string = this.formatBasicAuth(username, password);
    let csrfToken: string = "";
    this.getCsrfToken().subscribe(
      (data:HttpResponse<string>) => {
        // Daten erfolgreich geholt
        consoleextension.debuginfo("getCsrfToken Daten geholt:", data.body);
        csrfToken = data.body;

        returnId = this.postArtikelItem(
          basic, 
          csrfToken,
          title,
          body,
          reihenfolge);
        
        /*
        jQuery.ajax({
          url: 'http://example.com/entity/node?_format=hal_json',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken,
            'Authorization': basic
          },
          data: JSON.stringify(node),
          success: function (node) {
            console.log(node);
          }
        });
        */

        //returnId = 100;
      }, 
      (err) => {
        // Fehler
        consoleextension.error("getCsrfToken Fehler:", err);
      }, 
      () => {
        // Anfrage abgeschlossen
        consoleextension.debuglog("getCsrfToken abgeschlossen!", "");
      }
    );

    return returnId;
  }

  postArtikelItem(
    basic: string, 
    csrfToken: string, 
    title: string, 
    body: string, 
    reihenfolge: number
  ) : number {
    let returnId: number = -1;
    let d: Date = new Date();
    let url: string = this.serverUrl + `/drupal/node?_format=json&uid=${d.getTime()}`;
    /*
    let httpheaders: HttpHeaders = new HttpHeaders(); 
    httpheaders.set("Accept", "application/json");
    httpheaders.set("Content-Type", "application/json");
    httpheaders.set("X-CSRF-Token", csrfToken);
    httpheaders.set("Authorization", basic);
    */
    
    let headersJson: any = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
      'Authorization': basic
    }

    /*
    let bodyJson = {
      "type":[{"target_id":"artikel"}],
      "title":[{"value":"Hello World neu 333"}],
      "body":[{"value":"How are you neu 333?"}],
      "field_reihenfolge":[{"value":1}]
    }
    */

    let bodyJson = {
      "type":[{"target_id":"artikel"}],
      "title":[{"value":title}],
      "body":[{"value":body}],
      "field_reihenfolge":[{"value":reihenfolge}]
    }
    
    //consoleextension.debuginfo("HTTPHEADERS", httpheaders);
    consoleextension.debuginfo("HTTPHEADERS JSON", headersJson);

    this.http.post<IArtikelitem>(url, bodyJson, {headers: headersJson, observe: 'response' }).pipe(
      map(this.postedArtikelData),
      catchError(this.handleErrorObservablePostArtikelitem)
    ).subscribe();

    return 1000000;
  }

  private postedArtikelData(data) : number {
    consoleextension.debuginfo("postedArtikelData:", data);
    return 2000000;
  }

  private handleErrorObservablePostArtikelitem(err, caught: Observable<void>) {
    var userout = `Es ist ein Fehler beim Laden der Artikel aufgetreten!`;
    return this.handleError(err, userout);
  }

  getArtikelItems(): Observable<HttpResponse<IArtikelitem[]>> {
    let d: Date = new Date();
    let url: string = this.serverUrl + `/drupal/rest/views/artikel?_format=json&uid=${d.getTime()}`;
    if(consoleextension.isTestEnvironment == true) {
      // for test reasons only
      url = "/assets/testdata/artikelitems.json";
      consoleextension.info("URL:", url);
    }

    return this.http.get<IArtikelitem[]>(url, { observe: 'response' }).pipe(
      map(this.extractArtikelData),
      catchError(this.handleErrorObservableArtikelitem)
    );
  }
  private handleErrorObservableArtikelitem(err, caught: Observable<HttpResponse<IArtikelitem[]>>) {
    var userout = `Es ist ein Fehler beim Laden der Artikel aufgetreten!`;
    return this.handleError(err, userout);
  }
  private extractArtikelData(data) {
    try {
      // Dom-Variable zum Auslesen der zu erstellenden Image Elemente
      var doctype = document.implementation.createDocumentType(
        'html',
        '-//W3C//DTD XHTML 1.0 Strict//EN',
        'http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd'
      );
      var dom = document.implementation.createDocument(
        'http://www.w3.org/1999/xhtml',
        'html',
        doctype
      );
      
      // Artikel iterieren um für die Bilder artikelitemimages zu erstellen.
      data.body.forEach(artikel => {
        var artikelitemimages: IArtikelitemImage[] = [];
        var artikelitem: IArtikelitem = artikel;
        var artikelitemimagesstring = artikelitem.field_bild.split("<br>");
        var imageCount = 0;

        artikelitemimagesstring.forEach(image => {
          //<img src="/drupal/sites/default/files/2018-07/cassy_0.png" width="455" height="235" alt="Müde Cassy">
          image = image.trim();
          imageCount++;
          if(image.length > 0) {
            var template = dom.createElement('template');
            template.innerHTML = image;
            var imageelement : Element = template.content.firstElementChild;
            
            var artikelitemimage: IArtikelitemImage = {
              id: imageCount,
              artikelid: parseInt(artikelitem.nid),
              tag: "img",
              width: parseInt(imageelement.getAttribute("width")),
              height: parseInt(imageelement.getAttribute("height")),
              alt: imageelement.getAttribute("alt"),
              src: imageelement.getAttribute("src"),
              currentwidth: parseInt(imageelement.getAttribute("width")),
              currentheight: parseInt(imageelement.getAttribute("height")),
              currentoffsettop: 0,
              currentoffsetleft: 0
            };
            artikelitemimages[artikelitemimages.length] = artikelitemimage;
          }
          
        });

        artikel.show_editform = false;
        artikel.bilder = artikelitemimages;
      });
      return data;
    }
    catch(Ex) {
      consoleextension.error("Fehler in extractArtikelData:", Ex);
    }
  }

  /****************************************************************************
   * FootermenuItems
   ***************************************************************************/
  getFootermenuItems(): Observable<HttpResponse<IFootermenuitem[]>> {
    var d: Date = new Date();
    let url: string = this.serverUrl + `/drupal/rest/views/footermenu?_format=json&uid=${d.getTime()}`;
    if(consoleextension.isTestEnvironment == true) {
      // for test reasons only
      url = "/assets/testdata/footermenuitems.json";
      consoleextension.info("URL:", url);
    }

    return this.http.get<IFootermenuitem[]>(url, { observe: 'response' }).pipe(
      map(this.extractFootermenuItemsData),
      catchError(this.handleErrorObservableFootermenuitem)
    );
  }
  private handleErrorObservableFootermenuitem(err, caught: Observable<HttpResponse<IFootermenuitem[]>>) {
    var userout = `Das Menu mit den Daten zum Impressum und zum Datenschutz konnte nicht geladen werden: ` +
                  `Bitte lade die Seite erneut oder kontaktiere den Administrator!`;
    return this.handleError(err, userout);
  }
  private extractFootermenuItemsData(data) {
    return data;
  }

  /****************************************************************************
   * Errorhandler
   ***************************************************************************/
  private handleError(err, userout: string) {
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      consoleextension.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      consoleextension.error(
        `Backend returned code ${err.status}, ` +
        `body was: ${err.error}` + 
        `message was: ${err.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(userout);
  }
}
