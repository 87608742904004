import { Component, OnInit } from '@angular/core';
import { ContentDataService } from '../content-data.service';
import { IFootermenuitem } from '../footermenu/footermenuitem';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { consoleextension } from '../utilities/ConsoleExtension';

@Component({
  selector: 'app-footermenu',
  templateUrl: './footermenu.component.html',
  styleUrls: ['./footermenu.component.scss']
})
export class FootermenuComponent implements OnInit {

  public footermenuitems: IFootermenuitem[] = [];
  public errorMessage;

  constructor(
    private _contentDataService: ContentDataService, 
    private router: Router
  ) { }

  ngOnInit() {
    this._contentDataService.getFootermenuItems()
        .subscribe(
          (data:HttpResponse<IFootermenuitem[]>) => this.consumeFootermenuItems(data), 
          err => this.contentDataServiceError(err), 
          () => this.contentDataServiceComplete()
    );
  }

  private contentDataServiceComplete() {
    consoleextension.debuglog("Footermenu Service request complete!", "");
  }

  private contentDataServiceError(err) {
    this.errorMessage = err;
  }

  private consumeFootermenuItems(data: HttpResponse<IFootermenuitem[]>) {
    consoleextension.debuginfo("Footermenu Service request result: ", data);
    this.footermenuitems = data.body;
  }

  onClick(event, menuitemlink) {
    consoleextension.debuglog(menuitemlink);
    this.router.navigateByUrl(menuitemlink);
  }
}
