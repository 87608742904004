import { Component } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  welcomemessage = "ghvtheme";

  public constructor(private titleService: Title ) { }
 
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  
}
