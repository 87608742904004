import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seite-nicht-gefunden',
  template: `
    <h3>
      Die angeforderte Seite konnte nicht geladen werden!
    </h3>
  `,
  styles: []
})
export class SeiteNichtGefundenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
