import * as $ from 'jquery/dist/jquery';
import { Component, OnInit, HostListener } from '@angular/core';
import { ContentDataService } from '../content-data.service';
import { IArtikelitem } from '../artikel/artikelitem';
import { HttpResponse } from '@angular/common/http';
import { consoleextension } from '../utilities/ConsoleExtension';

@Component({
  selector: 'app-artikel',
  templateUrl: './artikel.component.html',
  styleUrls: ['./artikel.component.scss']
})
export class ArtikelComponent implements OnInit {

  public artikelitems: IArtikelitem[] = [];
  public errorMessage: string;
  public windowWidth: number = 0;
  public contentbodyWidth: number = 0;
  public contentbodyOffsetLeft: number = 0;
  public artikelitembildClass: string = "contentartikel-bild";
  public artikelitembildRightPadding: number = 15;
  public show_newform: boolean = false;
  public item_saved: boolean = false;
  public item_saved_id: number = 0;

  constructor(
    private _contentDataService: ContentDataService
  ) { }

  async startResizeAsync() {
    const value = <number>await this.waitASeconds();
  }

  waitASeconds() {
    return new Promise(resolve => {
      setTimeout(() => {
        consoleextension.debuglog("Resize now", "");
        this.resizeArtikelbilder();
      }, 1000);
    });
  }

  ngOnInit() {
    this._contentDataService.getArtikelItems()
        .subscribe(
          (data:HttpResponse<IArtikelitem[]>) => this.consumeArtikelItems(data), 
          err => this.contentDataServiceError(err), 
          () => this.contentDataServiceComplete()
    );
  }

  private contentDataServiceComplete() {
    consoleextension.debuglog("Artikel Service request complete!", "");
    this.startResizeAsync();
  }

  private contentDataServiceError(err) {
    this.errorMessage = err;
  }

  private consumeArtikelItems(data: HttpResponse<IArtikelitem[]>) {
    consoleextension.debuginfo("Artikel Service request result: ", data);
    this.artikelitems = data.body;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    consoleextension.debuglog("window:resize");
    this.windowWidth = event.target.innerWidth;
    this.resizeArtikelbilder();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationchange(event) {
    consoleextension.debuglog("window:orientationchange");
    //this.windowWidth = event.target.innerWidth;
    //this.resizeArtikelbilder();
  }

  menubareditbuttonOnClick(event, artikelitem: IArtikelitem) {
    consoleextension.debuginfo("menubareditbuttonOnClick:", event);
    consoleextension.debuginfo("on item:", artikelitem);
    
    artikelitem.show_editform=true;
  }

  menubarnewbuttonOnClick() {
    this.show_newform=true;
  }

  contentartikeleditformmenubarcancelbuttonOnClick(event, artikelitem: IArtikelitem) {
    consoleextension.debuginfo("contentartikeleditformmenubarcancelbuttonOnClick:", event);
    consoleextension.debuginfo("on item:", artikelitem);
    
    artikelitem.show_editform=false;
  }

  contentartikelnewformmenubarsavebuttonOnClick() {
    // save
    this.item_saved_id = this._contentDataService.createNewArtikelItem("administrator", "pega5u5", "Neuer Titel", "Neuer Body", 1);
    
    //this.item_saved_id = Math.floor(Math.random() * 6000) + 1;

    // confirm save
    this.item_saved = true;

    // reset form fields

    // return
    // this.show_newform=false;
  }

  contentartikelnewformmenubarcancelbuttonOnClick() {
    this.item_saved = false;
    this.show_newform=false;
  }

  resizeArtikelbilder() {
    // get contentbody width via jQuery
    var contentBodyElement = $(".contentbody-content");
    this.contentbodyWidth = contentBodyElement.innerWidth();
    this.contentbodyOffsetLeft = contentBodyElement.offset().left;

    // Bilder verkleinern, die größer als contentbodyWidth sind
    this.artikelitems.forEach(artikelitem => {
      if(artikelitem.bilder.length > 0) {
        artikelitem.bilder.forEach(artikelitembild => {
          // lese offset left
          var bildIdSelector: string = '#' + this.artikelitembildClass + '-' + artikelitembild.artikelid + '-' + artikelitembild.id;
          // aktualisiere offset left
          artikelitembild.currentoffsetleft = $(bildIdSelector).offset().left;
          var imageOffsetRight: number = artikelitembild.currentwidth + artikelitembild.currentoffsetleft;
          var contentbodyOffsetRight = this.contentbodyOffsetLeft + this.contentbodyWidth;

          if(contentbodyOffsetRight < imageOffsetRight) {
            // Bild verkleinern: currentwidth speichern
            artikelitembild.currentwidth = contentbodyOffsetRight - artikelitembild.currentoffsetleft - this.artikelitembildRightPadding;
          }
          else {
            if(artikelitembild.currentwidth < artikelitembild.width) {
              // Bild vergrößern, wenn nicht größer als das Ausgangsbild
              if((contentbodyOffsetRight - artikelitembild.currentoffsetleft) <= artikelitembild.width) {
                artikelitembild.currentwidth = contentbodyOffsetRight - artikelitembild.currentoffsetleft - this.artikelitembildRightPadding;
              }
            }
          }
        });
      }
    });
  }
}
