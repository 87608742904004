import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzVerbandComponent } from './datenschutz-verband/datenschutz-verband.component';
import { SeiteNichtGefundenComponent } from './seite-nicht-gefunden/seite-nicht-gefunden.component';
import { ArtikelComponent } from './artikel/artikel.component';

const routes: Routes = [
    { path: '', redirectTo: 'artikel', pathMatch: 'full' },
    { path: 'artikel', component: ArtikelComponent},
    { path: 'drupal/../artikel', component: ArtikelComponent },
    { path: 'impressum', component: ImpressumComponent },
    { path: 'drupal/../impressum', component: ImpressumComponent },
    { path: 'datenschutz', component: DatenschutzVerbandComponent },
    { path: 'drupal/../datenschutz', component: DatenschutzVerbandComponent },
    { path: '**', component: SeiteNichtGefundenComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
    ImpressumComponent, 
    DatenschutzVerbandComponent, 
    SeiteNichtGefundenComponent,
    ArtikelComponent
]